@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?yjkpoc');
  src:  url('../fonts/icomoon.eot?yjkpoc#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?yjkpoc') format('truetype'),
  url('../fonts/icomoon.woff?yjkpoc') format('woff'),
  url('../fonts/icomoon.svg?yjkpoc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map-pin-fill:before {
  content: "\e912";
}
.icon-pinterest:before {
  content: "\e910";
}
.icon-paperplane:before {
  content: "\e911";
}
.icon-sunglasses:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e90f";
}
.icon-arrow_left:before {
  content: "\e900";
}
.icon-arrow_right:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e902";
}
.icon-buegel:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
.icon-mouse:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e90d";
}
.icon-twitter-square:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\e90b";
}
.icon-facebook-square:before {
  content: "\e90c";
}
.icon-angle-right:before {
  content: "\e906";
}
.icon-angle-left:before {
  content: "\e907";
}
.icon-angle-up:before {
  content: "\e908";
}
.icon-angle-down:before {
  content: "\e909";
}
.icon-star:before {
  content: "\e913";
}
