// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "jquery.fancybox.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/conn-basics.scss";

// Font-Awesome
@import "font-awesome/font-awesome.scss";

// Icomoon
@import "icomoon/icomoon.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Selectric
@import "selectric/selectric";

//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: 44px; height: 44px; background-repeat: no-repeat; background-position: center; top: 50%; transform: translateY(-50%); z-index: 100; opacity: 0.5; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev:before { content: "\e907"; font-family:icomoon; width: 44px; height: 44px; font-size: 24px; }
.slick-next:before { content: "\e906"; font-family:icomoon; width: 44px; height: 44px; font-size: 24px; }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; }
.slick-dots:focus { outline: none; }
.slick-dots li { list-style-type: none; display: inline-block; }
.slick-dots li button { border: none; width: 15px; height: 15px; border-radius: 100%; font-size: 0; margin: 5px; background-color: $gray-light;  }
.slick-dots li.slick-active button { background-color: $gray-mid; }
.slick-dots li button:hover { background-color: $gray-mid; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider { margin: 0 -$grid-gutter-width/2; }
.content-slider--item { margin: 0 $grid-gutter-width/2; }

//Animatiertes Menüicon
.menu-icon { width: 40px; height: 44px; position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; }
.menu-icon span { display: block; position: absolute; height: 5px; width: 100%; background: $black; opacity: 1; left: 0; transform: rotate(0deg); transition: .25s ease-in-out; }
.menu-icon span:nth-child(1) { top: 7px; }
.menu-icon span:nth-child(2),.menu-icon span:nth-child(3) { top: 19px; }
.menu-icon span:nth-child(4) { top: 31px; }
.menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.menu-icon:focus { outline: none; }


nav ul { margin: 0; padding: 0; }
nav ul li { list-style-type: none; margin: 0; padding: 0; }

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

/*Wunschzettel*/

.wishlist-option {
  z-index: 999;
  position: relative;
  //margin-left: 10px;
  display: block;
  margin: 0 auto;
  top: -30px;
}

.popup-trigger {
  i {
    font-weight: bold;
  }
  .badge {
    position: relative;
    left: -15px;
    top: -18px;
    color: #000;
  }
}

.popup {
  &:before {
    display: block;
    content: '';
    background: url(../img/popup-arrow.jpg);
    width: 22px;
    height: 11px;
    margin-top: -28px;
    margin-left: 175px;
  }
  position: absolute;
  display: none;
  width: 250px;
  border: 1px solid #dedee0;
  right: -8px;
  background-color: rgba(255,255,255,.95)!important;
  border-radius: 4px;
  padding: 17px 15px 20px 15px!important;
  z-index: 2000;
  p {
    font-size: 13px!important;
  }
  .btn {
    margin-top: 10px;
    width: 100%;
  }
}

.popup__header {
  display: block;
  font-size: 14px!important;
  margin-top: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

.popup__number {
  float: right;
}

.popup__listitem > div {
  display: block;
  padding: 10px 0;
}

.popup__listitem .circle-border {
  border: 1px solid #eee;
}

.popup__listitem:not(:last-of-type) > div {
  border-bottom: 1px solid #dbdbdb;
}

.circle-border {
  display: block;
  width: 50px;
  height: 50px;
  float: left;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  background-color: #fff;
}

.popup__item-name {
  display: block;
  margin-top: 5px;
  color: #000;
  font-family: Bitter,sans-serif;
  font-size: 15px;
}

.popup__item-num {
  float: left;
  margin-top: 0;
  color: #6d7c83;
  font-family: Oxygen,sans-serif;
  font-size: 14px;
}

.popup__item-img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 3px;
}

.wishlist-outer {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;
}

.wishlist-outer .section-stores {
  border-top: none;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 30px;
}

.wishlist-outer .section-stores .results .dealers {
  max-height: 400px!important;
}

.wishlist-outer .section-stores .results {
  padding-top: 20px;
}

.wishlist-outer .section-stores .results .dealers .dealer {
  width: 100%;
}

.wishlist-outer .section-stores .trader__title {
  font-size: 20px;
  line-height: 60px;
}

.wishlist-outer .section-stores .trader__details.selected {
  border-left: 5px solid #9eca81;
  padding-left: 5px;
}

.wishlist-outer .section-request-form {
  padding-top: 30px;
}

.wishlist-outer h2 {
  font-size: 2.8rem!important;
}

.wishlist-outer .dealer-place-input {
  max-width: 100%!important;
}

/*Wunschzettel Ende*/


p {
  margin: 0;
}

@font-face {
  font-family: 'FuturaNormal';
  src:  url('../fonts/futuran-webfont.woff')  format('woff'),
        url('../fonts/futuran-webfont.woff2')  format('woff2')
}

@font-face {
  font-family: 'FuturaHeavy';
  src:  url('../fonts/futurah-webfont.woff')  format('woff'),
  url('../fonts/futurah-webfont.woff2')  format('woff2')
}

//Fancybox Customization

.fancybox-opened .fancybox-skin {
  box-shadow: none;
  background: #fff;
  color: #000;
  border: 1px solid #d2d2d2;
  border-radius: 0;
}

.fancybox-inner {
  height: auto!important;
  min-height: 150px;
}

.fancybox-inner > img {
  margin: 30px auto 55px auto;
  position: relative;
  display: block;
  //transform: translateY(-50%);
  //top: 50%;
}

.fancybox-arrow {
  font-size: 80px;
  display: inline-block;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  cursor: pointer;
  color: #d2d2d2;
  &:hover {
    color: $brand-primary;
  }
}

.fancy-next {
  right: 0;
}

.fancy-prev {
  left: 0;
}

.fancybox-title {
  font-family: 'FuturaNormal', sans-serif;
}

.model__colors {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.model__colors .color {
  height: 12px;
  width: 12px;
  display: inline-block;
  margin: 0 1px;
  border: 1px solid #000;
}


/** slick slider in FancyBox **/

.modell-slider-master img {
  //max-width: 600px;
  margin: 0 auto;
}

.modell-slider-thumb {
  width: 840px;
  height: 57px;
  max-width: 100%;
  margin-bottom: 20px;
}

.modell-slider-img-outer {
  margin: 0 5px;
  padding-bottom: 6px;
  height: 57px;
  border-bottom: 6px solid transparent;
}

.modell-slider-img-outer:focus {
  outline: none;
}

.modell-slider-border {
  border: 1px solid #ccc;
  height: 45px;
  overflow: hidden;
}

.modell-slider-img-outer.slick-current {
  border-bottom-color: #000;
}

.modell-slider-thumb #colorviewernav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.modell-slider-thumb #colorviewerprev i.fa,
.modell-slider-thumb #colorviewernext i.fa {
  color: #000;
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

.modell-slider-thumb #colorviewerprev {
  width: 30px;
  text-align: center;
  font-size: 18px;
  z-index: 100;
  cursor: pointer;
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  padding-bottom: 12px;
}

.modell-slider-thumb #colorviewernext {
  width: 30px;
  text-align: center;
  font-size: 18px;
  z-index: 100;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 0;
  height: 100%;
  padding-bottom: 12px;
}


/* Hauptstyles */

$mobile-menu-height: 60px;

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

*:focus {
  outline-color: $brand-primary;
}

body {
  @include font-size(1.4);
  font-family: 'FuturaNormal', sans-serif;
  color: $black;
}

@media (max-width: 767px) {
  body.fixed {
    overflow: hidden;
  }
}

h1, h2, h3, h4 {
  font-family: 'FuturaHeavy', sans-serif;
  margin-top: 0;
}

h1 {
  display: block;
  @include font-size(3.2);
  margin-bottom: 20px;
}

h2 {
  @include font-size(2.8);
  margin-bottom: 20px;
}

h3 {
  font-family: 'FuturaNormal', sans-serif;
  @include font-size(2.4);
}

h3.border-bottom {
  padding-bottom: 20px;
}

p {
  @include font-size(1.8);
}

.citylist-outer {
  margin-bottom: 40px;
}

.citylist {
  @include font-size(1.8);
}

.citylist__item {
  display: inline-block;
  padding-left: 20px;
}

.city-dealeritem {
  @include font-size(1.8);
  padding: 10px 0 10px 20px;
  //border-bottom: 1px solid #ccc;
}

#content ul {
  @include font-size(1.8);
}

#content .container > ul li {
  position: relative;
  padding: 0 0 0 15px;
  margin: 0 0 5px 0;
  //Square
  &:before {
    content: '';
    background-color: $brand-primary;
    display: block;
    width: 7px;
    height: 7px;
    margin-left: -15px;
    position: absolute;
    top: 9px;
  }
}

.trader__details {
  margin-left: 10px;
}

.dealer:hover {
  cursor: pointer;
}

.buegeldate {
  min-height: 200px;
}

.buegeldate h4 {
  background-color: #d2d2d2;
  padding: 10px;
}

.buegeldate__dealer {
  font-size: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #a9a9a9;
  padding: 10px;
}

.form-control {
  border-radius: 0;
}

.dealer:hover > .trader__details,
.dealer.active > .trader__details {
  border-left: 5px solid $brand-primary;
  padding-left: 5px;
}

//.dealer.active strong, .dealer:hover strong {
//  color: #75b34b;
//}

input {
  border-radius: 0!important;
  box-shadow: none!important;
}

header {
  position: relative;
  height: $mobile-menu-height;
}

.teaser-start.folge {
  margin-bottom: 40px;
}

.logo {
  display: block;
  position: relative;
  padding: 18px 0 0 0;
  width: 200px;
  float: left;
}

.logo > img {
  height: 30px;
}

.menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 10px 0 0;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
  border-radius: 0;
}

.search {
  position: relative;
}

.search form {
  margin: 10px;
}

.search input {
  width: 100%;
  height: 44px;
  border: none;
  padding: 0 50px 0 10px;
}

.search--btn {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 0;
  height: 44px;
  width: 44px;
  border: 0;
  background-color: transparent!important;
}

.search--btn img {
  height: 24px;
}

.nav-top {
  height: 100%;
}

.nav0-1 {
  display: none;
  position: fixed;
  background-color: #FFF;
  top: $mobile-menu-height;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.nav0-1 > ul {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.nav0-1 > ul > li > a {
  position: relative;
  font-family: FuturaHeavy, sans-serif;
  @include font-size(3.2);
  display: block;
  color: $black;
  padding: 10px 10px;
}

nav ul li div {
  display: none;
}

nav ul li div.active {
  display: block;
  background-color: lighten($brand-primary, 5%);
  padding: 0 0 0 20px;
}

.dropdown-icon {
  position: absolute;
  display: block;
  width: 44px;
  height: 44px;
  right: 0;
  top: 0;
  transition: all 0.3s;
}

.dropdown-icon.rotate {
  transform: scaleY(-1);
}

.dropdown-icon img {
  fill: #FFF;
  transition: transform 0.3s;
}

.trader__labels {
  margin-left: 10px;
}

.teaser-start {
  position: relative;
}

.teaser-start-slider {
  position: relative;
  padding: 0 10px;
  height: 165px;
  height: 50vw;
  max-height: 690px;
  overflow: hidden;
}

.teaser-start-slider .slick-arrow {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}

.teaser-start-slider:hover .slick-arrow {
  visibility: visible;
  opacity: 0.5;
}

.teaser-start-slider .slick-arrow:hover {
  opacity: 0.75;
}

.teaser-start-slider .slick-arrow:before {
  font-size: 100px;
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
  width: 40px;
  height: 100px;
  line-height: 100px;
  color: #FFF;
  text-shadow: 0 0 50px rgba(0, 0, 0, 1);
}

.teaser-start-slider .slick-prev {
  left: 10px;
  width: 40px;
  height: 100px;
}

.teaser-start-slider .slick-prev:before {
  content: "\e900";
}

.teaser-start-slider .slick-next {
  right: 10px;
  width: 40px;
  height: 100px;
}

.teaser-start-slider .slick-next:before {
  content: "\e901";
}

.teaser-start--image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 165px;
  height: 50vw;
}

.teaser-start.folge {
  height: 130px;
  height: 35vw;
}

.teaser-start.folge .teaser-start--image {
  height: 130px;
  height: 35vw;
}

.scroll-down-icon {
  position: absolute;
  border: 1px solid #FFF;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 100%;
  left: 50%;
  margin-left: -30px;
  bottom: -30px;
}

.scroll-down-icon:after {
  position: relative;
  content: "";
  display: block;
  background-color: $brand-primary;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  left: 3px;
  top: 3px;
}

.scroll-down-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  font-size: 24px;
  z-index: 1;
}

.section-qualitaet {
  padding: 50px 0 0 0;
}

.section-qualitaet div.col-xs-12:last-of-type {
  padding-bottom: 50px;
  background: $gray-mid;
  background: -moz-linear-gradient(top, #ffffff 0%, $gray-light 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, $gray-light 100%);
  background: linear-gradient(to bottom, #ffffff 0%, $gray-light 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d2d2d2', GradientType=0);
}

.section-qualitaet img {
  margin-bottom: 30px;
}

.section-qualitaet .btn {
  margin-top: 10px;
}

.section-modelle {
  position: relative;
  padding: 40px 0 40px 0;
}

.section-modelle > .container {
  position: relative;
}

.section-modelle--start:before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  bottom: -20px;
  left: 50%;
  margin-left: -60px;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-top: 20px solid $gray-mid;
}

.section-modelle--start:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  bottom: -19px;
  left: 50%;
  margin-left: -58px;
  border-left: 58px solid transparent;
  border-right: 58px solid transparent;
  border-top: 19px solid #fff;
}

.model-search {
  margin-bottom: 10px;
}

.model-search label {
  display: block;
  font-size: 20px;
}

.model-search > div {
  position: relative;
}

.model-search input {
  border: 1px solid $gray-mid;
  height: 44px;
  width: 100%;
  box-shadow: none;
  padding: 0 50px 0 10px;
  transition: border-color 0.3s;
}

.model-search input:focus {
  box-shadow: none;
  border-color: $gray-dark;
  outline-color: transparent;
}

.model-search button {
  position: absolute;
  border: none;
  background: transparent;
  width: 44px;
  height: 44px;
  text-align: center;
  right: 0;
  top: 0;
  font-size: 24px;
}

.filter-outer {
  position: relative;
  border-bottom: 1px solid $gray-mid;
  padding-bottom: 3px;
  margin-bottom: 10px;
}

.filter-outer .dropdown {
  position: static;
}

#filterung {
  width: 50%;
  float: left;
  font-size: 20px;
  border: none;
  background: none;
  font-weight: 700;
  padding: 0;
  text-align: left;
  line-height: 44px;
  outline: none!important;
}

#filterung + .dropdown-menu,
#filter-dropdown {
  width: 100%;
  border: 1px solid $gray-mid;
  //background-color: $gray-light;
  background-color: rgba(242, 243, 241,.9);
  box-shadow: none;
  border-radius: 0;
  padding: 15px;
  //top: 48px;
  //margin: 0;
  float: none;
  top: 183px;
}

.dropdown-menu.is-visible {
  display: block!important;
}

#filterung + .dropdown-menu ul,
#filter-dropdown ul {
  margin: 0 0 20px 0;
  padding: 0;
}

#filterung + .dropdown-menu ul li,
#filter-dropdown ul li {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 50%;
  float: left;
  &:before {
    display: none;
  }
}

#filterung + .dropdown-menu input[type="checkbox"],
#filter-dropdown input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid $gray-mid;
  position: relative;
  top: 4px;
  margin-right: 10px;
  margin-top: 0;
  background: #FFF;
  padding-left: 0;
}

#filterung + .dropdown-menu input[type="checkbox"]:focus,
#filter-dropdown input[type="checkbox"]:focus {
  outline-color: transparent;
}

#filterung + .dropdown-menu input[type="checkbox"]:checked:before,
#filter-dropdown input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: relative;
  top: 2px;
  left: 2px;
  background-color: $gray-mid;
}

.filter-title {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
}

#filterung + .dropdown-menu label,
#filter-dropdown label {
  font-weight: normal;
  font-size: 16px;
}

.sortierung {
  width: 50%;
  float: left;
}

.btn {
  display: inline-block;
  background-color: $brand-primary;
  color: #FFF;
  font-size: 20px;
  border-radius: 10px;
  padding: 9px 30px 9px 30px;
  transition: all 0.3s;
}

.btn:active,
.btn:hover,
.btn:focus {
  outline-color: transparent;
  color: #FFF;
  box-shadow: none;
  background: darken($brand-primary,15%);
}

.arrow-right:after {
  font-family: icomoon;
  content: "\e906";
  margin-left: 15px;
  display: inline-block;
}

.arrow-down:after {
  font-family: icomoon;
  content: "\e909";
  margin-left: 15px;
  display: inline-block;
}

.filter-color--noslider {
  width: 100%;
}

.filter-color--slider .option-container input,
.filter-color--noslider .option-container input {
  appearance: none;
  display: none;
  opacity: 0;
}

.filter-color--slider {
  margin-left: 25px;
  margin-right: 25px;
  width: 210px;
}


.filter-color--slider .option-container input + .option-color-label,
.filter-color--noslider .option-container input + .option-color-label {
  display: block;
  height: 45px;
  width: 45px;
  transition: all 0.2s;
  margin: 0 5px;
  border: 1px solid #d2d2d2;
}

.filter-color--noslider .option-container input + .option-color-label {
  float: left;
  margin: 0 8px 8px 0;
}

.filter-color--slider .option-container input:checked + .option-color-label,
.filter-color--noslider .option-container input:checked + .option-color-label {
  border: 1px solid $gray-dark;
  box-shadow: inset 0 0 0 2px #FFF;
}

.filter-color--slider .slick-prev {
  left: -40px;
}

.filter-color--slider .slick-next {
  right: -40px;
}

#filterung[aria-expanded="false"] + .dropdown-menu {
  display: block!important;
  height: 0;
  overflow: hidden;
  padding: 0;
  border: none;
}

#filterung[aria-expanded="true"] + .dropdown-menu {
  display: block!important;
  height: auto;
}

#filterung[aria-expanded="true"]:before,
#filterung.opened:before {
   content: "";
   display: block;
   width: 0;
   height: 0;
   border-left: 15px solid transparent;
   border-right: 15px solid transparent;
   border-bottom: 10px solid $gray-mid;
   position: absolute;
   bottom: 0;
   left: 20px;
   z-index: 1001;
}

#filterung[aria-expanded="true"]:after,
#filterung.opened:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 9px solid $gray-light;
  position: absolute;
  bottom: 0;
  left: 22px;
  z-index: 1001;
}

#filterung[aria-expanded="true"] span:after,
#filterung.opened span:after {
  transform: rotate(180deg);
}

.sortierung .selectric-items {
  left: -10px;
  top: 49px;
  border-color: $gray-mid;
  background: $gray-light;
  box-shadow: none;
}

.selectric-items li {
  border: none;
  font-size: 18px;
  padding: 10px;
  color: $gray-dark;
}

.model-slider {
  padding-top: 20px;
  padding-bottom: 30px;
}

.model-item {
  position: relative;
  display: block;
  min-height: 120px;
  border: 1px solid #FFF;
  padding: 5px;
  transition: border 0.3s;
}

.model-item:focus {
  outline: transparent;
}

.model-item:hover,
.touch .model-item:focus {
  outline-color: transparent;
}

.model-item .model-item--title {
  font-weight: bold;
  font-size: 12px;
  color: #000;
  margin-bottom: 10px;
  height: 50px;
}

.model-item  .model-item--subtitle {
  color: #7f7f7f;
  font-size: 10px;
}

//.model-item--subtitle > div:last-of-type,
//.model-item--title > div:last-of-type {
//  text-align: right;
//}

.col-artnum,
.col-pricecat {
  text-align: right;
}

.model-item--img {
  position: relative;
}

.model-item .model-item--img .image-container {
  display: block;
  height: 165px;
  overflow: hidden;
}

.model-item .model-item--img img {
  margin: 0 auto;
}

.model-item .model-item--img .image-container img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}

.model-item .model-item--img {
  transform: scale(1);
  transition: transform 0.3s;
}

.model-item:hover .model-item--img,
.touch .model-item:focus .model-item--img {
  transform: scale(0.9);
}

.model-item .model-item--title,
.model-item .model-item--subtitle {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.model-item:hover .model-item--title,
.model-item:hover .model-item--subtitle,
.touch .model-item:focus .model-item--title,
.touch .model-item:focus .model-item--subtitle {
  visibility: visible;
  opacity: 1;
}

.model-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.buegel-dev .model-item .overlay {
  text-align: center;
}

.model-item .overlay i[class^="icon"] {
  display: block;
  visibility: hidden;
  position: relative;
  width: 60px;
  height: 60px;
  border: 1px solid #FFF;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 110px;
  //top: 50%;
  //margin-top: -55px;
  //transform: translateY(-50%);
}

.buegel-dev .model-item .overlay i[class^="icon"] {
  display: inline-block;
}

.buegel .model-item .overlay i[class^="icon"] {
  margin-top: 60px;
}

.model-item:hover .overlay i[class^="icon"],
.touch .model-item:focus .overlay i[class^="icon"] {
  visibility: visible;
}

.model-item .overlay i[class^="icon"]:before {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: $brand-primary;
  color: rgba(255,255,255,0);
  border-radius: 100%;
  font-size: 18px;
  padding: 0;
  margin: 0 auto;
  transition: width 0.3s, height 0.3s, padding 0.3s, color 0.3s 0.2s;
  overflow: hidden;
}

.model-item:hover,
.touch .model-item:focus {
  border: 1px solid $gray-mid;
}

.model-item:hover .overlay i[class^="icon"]:before,
.touch .model-item:focus .overlay i[class^="icon"]:before {
  width: 50px;
  height: 50px;
  padding: 16px;
  color: rgba(255,255,255,1);
}

.model-item--sale-badge {
  position: absolute;
  top: 15px;
  right: 20px;
}
.model-item--sale-badge > img {
  width: 65px;
}

.fancybox-skin .model-item--sale-badge {
  top: 0;
  left: 0;
  right: inherit;
}

footer {
  padding: 30px 0 0 0;
  background-color: $gray-mid;
  color: #474747;
}

.footer-title {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
}

footer ul {
  margin: 0 0 30px 0;
  padding: 0;
}

footer ul li {
  margin: 0 0 5px 0;
  padding: 0;
  list-style: none;
}

footer ul li a {
  font-size: 14px;
  color: #474747;
  transition: color 0.2s;
}

footer ul li a:hover {
  color: #000!important;
}

footer ul li:before {
  content: "\e906";
  font-family: icomoon;
  padding-right: 10px;
  color: #474747;
}

.social-icons {
  margin-top: -20px;
}

.horizontal-list li {
  float: left;
  margin-right: 15px;
}

.horizontal-list li:before {
  content: none;
}

.horizontal-list.social-icons li a {
  font-size: 24px;
  color: #474747;
}

input:not(.submit_but_save, .input-checkbox) {
  border: 1px solid $gray-mid;
  height: 44px;
  width: 100%;
  box-shadow: none;
  padding: 0 10px 0 10px;
  transition: border-color 0.3s;
}

input:focus {
  box-shadow: none;
  border-color: $gray-dark;
  outline-color: transparent;
}

.dealer-place-input {
  margin-bottom: 10px;
}

.dealer-search {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid $gray-mid;
}

.dealer-search button {
  width: 100%;
  padding: 8px 10px;
  height: 44px;
}

.premium-badge {
  padding: 2px 5px;
  border: 1px solid $gray-mid;
  display: inline-block;
  margin-top: 5px;
  background-color: #FFF;
}

.section-stores {
  padding: 50px 0 60px 0;
  border-top: 1px solid $gray-mid;
  background-color: $gray-light;
}

.section-stores .results .dealers {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.section-stores .results .dealers .dealer {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.section-stores .results .dealers .dealer:last-of-type {
  border-bottom: none;
}

footer:not(.footer-start) {
  margin-top: 50px;
}

footer table {
  font-size: 12px;
}

.footer-col p {
  font-size: 14px;
  margin: 0;
  color: #474747;
}

footer .bottom-line {
  border-top: 1px solid #a9a9a9;
  padding-top: 20px;
  margin-top: 50px;
  padding-bottom: 40px;
  color: #474747;
}

footer .copyright {
  float: right;
}

footer .bottom-line a {
  color: #474747;
}

footer .languages {
  float: left;
}

footer .pull-left {
  margin-right: 10px;
}

footer .languages a {
  display: inline-block;
  float: left;
  text-transform: uppercase;
}

footer .languages a:not(:last-child) {
  &:after {
    content: '|';
  }
}

.section-kataloge {
  padding: 0 0 50px 0;
}

.katalog-box {
  display: block;
  margin-bottom: 30px;
}

.katalog-box:hover .katalog-box--image img {
  transform: scale(1.05);
}

.katalog-box .btn-katalog {
  transition: background 0.3s;
}

.katalog-box:hover .btn-katalog {
  background: darken($brand-primary,15%);
}

.katalog-box .katalog-box--image img {
  transition: transform 0.5s;
}

.katalog-box--image {
  display: block;
  border: 1px solid $gray-light;
  padding: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.btn-katalog {
  width: 100%;
}

.pagination-outer {
  text-align: center;
}

.pagination>li {
  position: relative;
}

.pagination>li>a, .pagination>li>span {
  position: relative;
  border: none;
  font-size: 18px;
}

.pagination>li>a:hover, .pagination>li>span:hover, .pagination>li.aktiv > a {
  background: none;
  color: $brand-primary;
}

.pagination>li:not(:first-child):not(:last-child)>a, .pagination>li:not(:first-child):not(:last-child)>span {
  line-height: 54px;
}

.pagination>li:not(:first-child):not(:last-child)>a:after, .pagination>li:not(:first-child):not(:last-child)>span:after {
  content: "";
  display: block;
  width: 1px;
  height: 18px;
  position: absolute;
  background-color: $gray-mid;
  right: 1px;
  top: 24px;
}

.pagination>li:nth-last-child(2)>a:after, .pagination>li:nth-last-child(2)>span:after  {
  content: none!important;
}

.pagination>li>a>i {
  font-size: 54px;
  color: $gray-mid;
}

.pagination>li>a>i:hover {
  color: $brand-primary;
}

.border-bottom {
  border-bottom: 1px solid $gray-mid;
  margin-bottom: 20px;
}

.section-kataloge {
  max-width: 380px;
  margin: 0 auto;
}

.section-modelle.buegel .model-item .model-item--img {
  height: 80px;
  margin-top: 12px;
}

.section-modelle.buegel .model-item .model-item--img .image-container {
  height: 45px;
  margin-bottom: 5px;
}

.info-box {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 10px 10px 10px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;
}

.close-info-box:focus,
.star-info-box:focus {
  outline: none;
}

//.close-info-box {
//  margin: 0;
//  border: none;
//  background: none;
//  font-size: 30px;
//  line-height: 28px;
//  width: 44px;
//  height: 44px;
//  padding: 6px;
//  z-index: 2000;
//  position: relative;
//}

.close-info-box,
.star-info-box {
  border: none;
  background: 0 0;
  font-size: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.star-info-box {
  right: 60px;
}

.star-info-box span {
  display: block;
  height: 30px;
  width: 30px;
  background: #9eca81;
  margin-top: 4px;
  border-radius: 100%;
  overflow: hidden;
}

.star-info-box span i.icon-star {
  color: #fff;
  font-size: 16px;
  position: relative;
  top: -9px;
}

button.delete-from-wishlist i.icon-star:before,
.delete-from-wishlist.icon-star:before{
  color: #000000!important;
}

.info-box--image {
  height: 80px;
  margin-bottom: 20px;
}

.info-box--image img {
  max-width: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%,-50%);
}

.section-modelle.buegel:after {
  content: none;
}

.newsrow {
  margin-left: -15px;
  margin-right: -15px;
}

.newscol {
  padding-left: 15px;
  padding-right: 15px;
}

.newsimage {
  display: block;
  background-position: center center;
  height: 240px;
  background-size: cover;
  margin-bottom: 15px;
}

.newstitle {
  display: block;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-family: FuturaHeavy, sans-serif;
  margin-top: 5px;
  margin-bottom: 3px;
  line-height: 24px;
  min-height: 46px;
}

.newsdate {
  display: block;
  text-align: center;
  font-size: 14px;
  font-style: italic;
}

.newstext {
  text-align: center;
  margin-bottom: 12px;
  font-size: 18px;
}

.newsbtn-outer {
  text-align: center;
  margin-bottom: 40px;
}

.newsbtn {
  padding: 6px 12px!important;
  font-size: 18px;
}

.katalog-subtitle {
  margin-bottom: 10px;
}

.pricecat-right {
  float: right;
}


@media (min-width: 380px) {

  .filter-color--slider {
    width: 265px;
  }

}

@media (min-width: 420px) {

  .filter-color--slider {
    width: 320px;
  }

}

@media (min-width: 560px) {

  .filter-color--slider {
    width: 430px
  }

  #filterung + .dropdown-menu ul li {width:25%;}
  #filter-dropdown ul li {width:25%;}

}

@media (max-width: 767px) {
  .fancybox-skin {
    padding: 30px 10px 10px 10px!important;
  }
  .modell-slider-master {
    margin-bottom: 50px;
  }
  .fancybox-subinfos {
    font-size: 12px;
  }
  .info-box--productnr {
    font-size: 12px;
  }
  h3.border-bottom {
    padding-bottom: 10px;
  }
  .border-bottom {
    margin-bottom: 10px;
  }
  .close-info-box {
    right: 5px;
  }

  .popup-outer {
    top: 18px;
    position: relative;
  }

  .popup-trigger {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 20px;
    float: right;
    margin-right: 50px;
  }

  .popup {
    top: 42px;
    right: 0;
    &:before {
      margin-left: 136px;
    }
  }
}

/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .stockists-bottom {
    //padding-top: 85px!important;
    padding-top: 40px!important;
    padding-bottom: 25px!important;
  }

  .katalog-subtitle {
    min-height: 44px;
  }

  .modell-slider-thumb {
    height: 77px;
  }

  .fancybox-model .fancybox-inner .modell-slider-thumb img {
    min-height: 65px;
    max-height: unset;
  }

  .modell-slider-img-outer {
    height: 77px;
  }

  .modell-slider-border {
    height: 65px;
  }

  #map {
    height: 400px;
  }

  p {
    font-size: 16px;
  }

  .container {
    //width: 768px;
  }

  header {
    height: 100px;
    padding-top: 20px;
  }

  .logo {
    float: left;
    padding: 16px 0 0 0;
    width: 250px;
  }

  .logo img {
    height: 39px;
  }

  .nav-top {
    position: relative;
    height: auto;
  }

  .nav0-1 {
    display: block!important;
    position: relative;
    top: 14px;
    bottom: 0;
    padding: 0;
    float: right;
    background: transparent;
    width: auto;
  }

  .nav0-1 > ul {
    position: relative;
    transform: none;
    top: 0;
  }

  .nav0-1 > ul > li {
    width: auto;
    text-align: left;
    transition: all 0.3s;
    float: left;
    margin-right: 25px;
  }

  .nav0-1 > ul > li:last-of-type {
    margin-right: 0;
  }

  .nav0-1 > ul > li > a {
    font-size: 20px;
    padding: 10px 0;
  }

  .nav0-1 > ul > li > a:hover {
    background: none;
    color: $brand-primary;
  }

  .nav0-2 {
    position: absolute;
    width: 100%;
    background-color: #FFF;
    left: 0;
  }

  .nav0-2 > ul > li > a {
    color: $brand-primary;
  }

  .global {
    float: right;
    padding: 20px 0;
  }

  .global ul li {
    float: left;
    list-style-type: none;
    padding: 0 0 0 50px;
  }

  .global ul li a {
    position: relative;
  }
  
  .global ul li a:before {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    width: 16px;
    height: 16px;
    background-image: url('../img/home.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }

  .search {
    position: absolute;
    top: -42px;
    right: 0;
  }

  .search form {
    margin: 0;
  }

  .search input {
    border: 1px solid $brand-primary;
    height: 32px;
  }

  .search--btn {
    right: 0;
    width: 32px;
    height: 32px;
  }

  .teaser-start.folge {
    height: auto;
  }

  .teaser-start.folge {
    height: 155px;
    height: 20vw;
  }

  .teaser-start.folge .teaser-start--image {
    height: 100%;
    background-size: contain;
    background-position: top center;
  }

  .section-qualitaet {
    padding: 75px 0 50px 0;
    background: $gray-mid;
    background: -moz-linear-gradient(top, #ffffff 0%, $gray-light 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, $gray-light 100%);
    background: linear-gradient(to bottom, #ffffff 0%, $gray-light 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d2d2d2', GradientType=0);
  }

  .section-qualitaet div.col-xs-12:last-of-type {
    background: none;
    padding-bottom: 0;
  }

  .filter-outer {
    border-bottom: 0;
    margin: 0;
    padding: 0;
  }

  .model-search-outer {
    position: relative;
    padding-bottom: 5px;
  }

  .model-search label {
    float: left;
    margin-right: 10px;
    margin-bottom: 0;
    line-height: 34px;
  }

  .model-search > div {
    float: left;
  }

  .model-search input {
    height: 34px;
    width: 180px;
  }

  .model-search button {
    height: 34px;
    width: 34px;
    right: -34px;
    font-size: 18px;
  }

  #filterung {
    position: relative;
    line-height: 32px;
  }

  .filter-outer {
    position: static;
  }

  .static {
    position: static;
  }

  //#filterung+.dropdown-menu {
  //  left: 50%;
  //  width: 720px;
  //  margin-left: -360px;
  //  top: 144px;
  //}

  .model-slider {
    border-top: 1px solid $gray-mid;
    padding-top: 20px;
    padding-bottom: 50px;
    margin-top: 5px;
  }

  .overflow-visible .slick-list {
    overflow: visible;
  }

  #filter-dropdown {
    top: 97px;
  }

  #filterung[aria-expanded=true]:before,
  #filterung.opened:before {
    bottom: -16px;
  }

  #filterung[aria-expanded=true]:after,
  #filterung.opened:after {
    bottom: -16px;
  }

  #filterung+.dropdown-menu ul.glasses-for li,
  #filter-dropdown ul.glasses-for li {
    width: 100%;
  }

  #filterung+.dropdown-menu ul.material li,
  #filter-dropdown ul.material li {
    width: 50%;
  }

  .filter-color--slider {
    width: auto;
  }

  .section-qualitaet img {
    margin: 0;
  }

  .image-wrapper {
    height: 200px;
  }

  .model-item {
    transition: transform 0.3s;
  }

  .section-modelle.buegel .model-item {
    transform: scale(1.0);
  }

  .section-modelle.buegel .model-item:hover {
    transform: scale(1.1);
  }

  .section-modelle.brillen .model-item {
    transform: scale(0.9);
  }

  .section-modelle.brillen .model-item:hover {
    transform: scale(1.0);
  }

  .model-item:hover {
    transform: scale(0.9);
  }

  .model-item:hover {
    transform: scale(1.0);
  }

  .model-item .model-item--img {
    transform: scale(1.0);
    transition: transform 0.3s;
  }

  .model-item:hover .model-item--img,
  .touch .model-item:focus .model-item--img {
    transform: scale(1.0);
  }

  .image-wrapper {
    height: 180px;
  }

  .section-stores .results .dealers .dealer {
    width: 350px;
  }

  .section-stores .results .dealers {
    height: 400px;
  }

  .footer-title {
    margin-bottom: 20px;
  }

  .dealer-search .selectric .label {
    height: 44px;
    line-height: 42px;
  }

  .btn-katalog {
    font-size: 14px;
    padding: 5px 0!important;
  }

  footer {
    padding-top: 50px;
  }

  .section-kataloge {
    max-width: none;
  }

  .section-modelle.buegel .model-item .model-item--img {
    height: 80px;
    margin-top: 30px;
    margin-bottom: 12px;
  }

  .section-modelle.buegel .model-item .model-item--img .image-container {
    height: 45px;
  }

  .section-modelle.buegel .image-wrapper {
    height: auto;
  }

  .product-list {
    margin-top: 5px;
    padding-top: 20px;
    border-top: 1px solid $gray-mid;
    min-height: 400px;
  }

  .info-box {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    bottom: auto;
    height: auto;
    width: 740px;
    border: 1px solid $gray-mid;
    padding-bottom: 40px;
  }

  .info-box--image {
    height: 200px;
  }

  .teaser-start-slider .slick-arrow {
    width: 80px;
    height: 200px;
  }

  .teaser-start-slider .slick-arrow:before {
    font-size: 200px;
    line-height: 200px;
    left: -60px;
  }

  .teaser-start-slider .slick-prev {
    left: 50px;
  }

  .teaser-start-slider .slick-next {
    right: 50px;
  }

  .dealer-search .selectric-items {
    left: -10px;
  }

  .footer-col__tab {
    display: inline-block;
    width: 145px;
    float: left;
  }

}

@media (min-width: $screen-tablet) and (max-width: 991px) {
  .newsimage {
    height: 160px;
  }
}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .btn-katalog {
    font-size: 18px;
  }

  p {
    font-size: 18px;
  }

  section h2 {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .container {
    width: 970px;
  }

  .border-bottom {
    margin-bottom: 40px;
  }

  .nav0-1 {
    top: 10px;
  }

  .nav0-1>ul>li {
    margin-right: 50px;
  }

  .nav0-1>ul>li>a {
    font-size: 24px;
  }

  .section-qualitaet img {
    max-width: 420px;
  }

  .scroll-down-icon {
    width: 110px;
    height: 110px;
    margin-left: -55px;
    bottom: -55px;
  }

  .scroll-down-icon:after {
    width: 100px;
    height: 100px;
    left: 4px;
    top: 4px;
  }

  .scroll-down-icon i {
    font-size: 48px;
  }

  .section-qualitaet {
    padding: 120px 0 60px 0;
  }

  .model-item .model-item--img .image-container {
    height: 175px;
  }

  #filterung {
    line-height: 44px;
    height: 44px;
  }

  .selectric .label {
    line-height: 42px;
    height: 44px;
  }

  .selectric .label,
  #filterung,
  .model-search label {
    font-size: 18px!important;
  }

  .sortierung .selectric-items {
    top: 59px;
    width: auto!important;
  }

  .section-modelle h2 {
    margin-bottom: 0;
  }

  //#filterung+.dropdown-menu {
  //  top: 106px;
  //  width: 950px;
  //  margin-left: -475px;
  //}

  //.section-modelle.buegel #filterung+.dropdown-menu {
  //  top: 156px;
  //}

  #filter-dropdown {
    top: 64px;
  }

  .filter-outer {
    margin-left: 30px;
    margin-top: 7px;
  }

  .model-slider {
    margin-top: 15px;
  }

  .model-item {
    padding: 10px;
  }

  .model-item .model-item--title {
    font-size: 15px;
  }

  .model-item .model-item--subtitle {
    font-size: 13px;
  }

  .model-search {
    margin: 14px 0 0 0;
  }

  .model-search input {
    //width: 200px;
  }

  .model-item .overlay i[class^="icon"] {
    width: 110px;
    height: 110px;
  }

  .model-item:hover .overlay i[class^="icon"]:before, .touch .model-item:focus .overlay i[class^="icon"]:before {
    width: 100px;
    height: 100px;
  }

  .model-item .overlay i[class^="icon"]:before {
    font-size: 42px;
    text-align: center;
  }

  .model-item:hover .overlay i[class^="icon"]:before, .touch .model-item:focus .overlay i[class^="icon"]:before {
    padding: 28px;
  }

  .section-stores {
    padding-bottom: 80px;
  }

  .section-stores .results .dealers .dealer {
    width: 450px;
  }

  .section-stores .results .dealers {
    height: 500px;
  }

  .dealer-search {
    border: none;
    margin: 6px 0 0 0;
    padding: 0;
  }

  .dealer-place-input {
    max-width: 190px;
  }

  .section-stores .results {
    border-top: 1px solid $gray-mid;
    padding-top: 20px;
  }

  .selectric .label {
    font-size: 20px;
    line-height: 42px;
    height: 44px;
  }

  .dealer-place-input {
    font-size: 18px;
  }

  .teaser-start-slider {
    height: 400px;
  }

  .teaser-start--image {
    height: 400px!important;
  }

  .teaser-start.folge .teaser-start--image {
    height: 155px!important;
    height: 20vw!important;
  }

  .teaser-start.folge .teaser-start--image,
  .teaser-start--image {
    background-size: cover;
    background-position: center;
    width: 100%;
  }

  .btn {
    padding: 9px 30px 9px 30px;
  }

  .section-stores {
    padding-top: 60px;
  }

  .section-kataloge {
    padding: 0 0 70px 0;
  }

  .btn-katalog {
    font-size: 20px;
  }

  .katalog-subtitle {
    min-height: 50px;
  }

  .section-modelle.buegel {
    position: relative;
    padding: 90px 0 30px 0;
  }

  .product-list {
    margin-top: 16px;
    padding-top: 30px;
  }

  .section-modelle.buegel .model-item .model-item--img {
    margin-top: 30px;
  }

  .info-box {
    width: 970px;
    padding-left: 40px;
    padding-right: 40px;
  }

  //.close-info-box {
  //  margin-right: -30px;
  //}

  #map {
    height: 500px;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .trader__labels {
    margin-left: -10px;
  }

  .container {
    width: 1120px;
  }

  .section-qualitaet {
    padding: 160px 0 60px 0;
  }

  .nav0-1>ul>li {
    margin-right: 65px;
    //margin-right: 85px;
  }

  .section-qualitaet img {
    max-width: 470px;
  }

  .teaser-start-slider {
    height: 690px;
    max-width: 1860px;
    margin: 0 auto;
    padding: 0;
  }

  .teaser-start--image {
    height: 690px!important;
  }

  .teaser-start.folge {
    height: 390px;
    max-width: 1860px;
    margin: 0 auto 80px auto;
    padding: 0;
  }

  .teaser-start.folge .teaser-start--image {
    height: 390px!important;
  }

  .model-search {
    margin: 14px 0 0 20px;
  }

  .model-search label {
    font-size: 20px!important;
    margin-right: 20px;
  }

  .model-search input {
    width: 280px;
  }

  .filter-outer {
    margin-left: 20px;
  }

  #filterung .arrow-down:after {
    margin-left: 20px;
  }

  .sortierung .selectric {
    text-align: right;
  }

  .sortierung .selectric .label:after {
    padding-left: 20px;
  }

  //#filterung+.dropdown-menu {
  //  width: 1100px;
  //  margin-left: -550px;
  //}

  .model-slider {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  .model-item {
    padding: 15px 20px;
  }

  .filter-color--slider {
    width: 430px;
  }

  #filter-dropdown {
    top: 64px;
  }

  #filterung + .dropdown-menu ul.material li,
  #filter-dropdown ul.material li {
    width: 33.333%;
  }

  .model-item .model-item--title {
    font-size: 18px;
  }

  .model-item .model-item--img {
    transform: scale(1.0);
    transition: transform 0.3s;
  }

  .model-item:hover .model-item--img,
  .touch .model-item:focus .model-item--img {
    transform: scale(1.1);
  }

  .model-slider .slick-dots {
    margin-top: 30px;
  }

  .section-modelle {
    padding-bottom: 90px;
  }

  .section-stores {
    padding: 60px 0 110px 0;
  }

  .section-stores .results {
    padding-top: 40px;
  }

  .dealer-search {
    margin: 10px 0 0 0;
  }

  .section-kataloge {
    padding: 0 0 100px 0;
  }

  .product-list {
    padding-top: 30px;
  }

  .pagination-outer {
    margin: 55px 0 60px 0;
  }

  .pagination>li>a>i {
    font-size: 105px;
  }

  .pagination>li:first-child>a, .pagination>li:first-child>span,
  .pagination>li:last-child>a, .pagination>li:last-child>span {
    width: 80px;
    padding: 0;
  }

  .pagination>li:not(:first-child):not(:last-child)>a, .pagination>li:not(:first-child):not(:last-child)>span {
    line-height: 85px;
    padding: 6px 18px;
    font-size: 20px;
    font-weight: bold;
  }

  .pagination>li:last-child>a>i, .pagination>li:last-child>span>i {
    margin-left: -20px;
  }

  .pagination>li:not(:first-child):not(:last-child)>a:after, .pagination>li:not(:first-child):not(:last-child)>span:after {
    top: 38px;
  }

  .info-box {
    width: 1160px;
    padding-left: 80px;
    padding-right: 80px;
  }

  .info-box--image {
    height: 240px;
    margin-bottom: 55px;
  }

  //.close-info-box {
  //  margin-top: 10px;
  //  margin-right: -60px;
  //}

  h3 {
    font-size: 26px;
  }

  h3.border-bottom {
    margin-bottom: 25px;
  }

  .teaser-start-slider .slick-arrow {
    width: 120px;
    height: 300px;
  }

  .teaser-start-slider .slick-arrow:before {
    font-size: 300px;
    line-height: 300px;
    left: -90px;
  }

  .teaser-start-slider .slick-prev {
    left: 50px;
  }

  .teaser-start-slider .slick-next {
    right: 50px;
  }

  .section-stores .results .dealers .dealer {
    width: 515px;
  }

  .premium-badge {
    margin-top: 3px;
    float: right;
  }

}